import codeFill from '@iconify/icons-eva/code-fill';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  home: getIcon('ic_home'),
  city: getIcon('ic_city'),
  country: getIcon('ic_country'),
  destination: getIcon('ic_compass'),
  experience: getIcon('ic_airplane_place'),
  interaction: getIcon('ic_message'),
  user: getIcon('ic_user'),
  communications: getIcon('ic_chat')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      {
        title: 'home',
        path: PATH_DASHBOARD.root,
        icon: ICONS.home
      },
      // {
      //   title: 'cities',
      //   path: PATH_DASHBOARD.cities.root,
      //   icon: ICONS.city,
      //   children: [
      //     { title: 'search greeters', path: PATH_DASHBOARD.cities.greeters },
      //     { title: 'search travelers', path: PATH_DASHBOARD.cities.travelers }
      //   ]
      // },
      // {
      //   title: 'countries',
      //   path: PATH_DASHBOARD.countries.root,
      //   icon: ICONS.country,
      //   children: [
      //     { title: 'search greeters', path: PATH_DASHBOARD.countries.greeters },
      //     { title: 'search travelers', path: PATH_DASHBOARD.countries.travelers }
      //   ]
      // },
      {
        title: 'communications',
        path: PATH_DASHBOARD.communications,
        icon: ICONS.communications
      },
      {
        title: 'cities',
        path: PATH_DASHBOARD.cities.root,
        icon: ICONS.city
      },
      {
        title: 'countries',
        path: PATH_DASHBOARD.countries.root,
        icon: ICONS.country
      },
      {
        title: 'destinations',
        path: PATH_DASHBOARD.destinations,
        icon: ICONS.destination
      },
      {
        title: 'experiences',
        path: PATH_DASHBOARD.experiences,
        icon: ICONS.experience
      },
      {
        title: 'interactions',
        path: PATH_DASHBOARD.interactions,
        icon: ICONS.interaction
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
        children: [
          { title: 'admins', path: PATH_DASHBOARD.admins.root },
          { title: 'greeters', path: PATH_DASHBOARD.greeters.root },
          { title: 'travelers', path: PATH_DASHBOARD.travelers.root }
        ]
      }
    ]
  }

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     // {
  //     //   title: 'user',
  //     //   path: PATH_DASHBOARD.user.root,
  //     //   icon: ICONS.user,
  //     //   children: [
  //     //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //     //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //     //     { title: 'list', path: PATH_DASHBOARD.user.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //     //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //     //     { title: 'account', path: PATH_DASHBOARD.user.account }
  //     //   ]
  //     // },

  //     // MANAGEMENT : E-COMMERCE
  //     // {
  //     //   title: 'e-commerce',
  //     //   path: PATH_DASHBOARD.eCommerce.root,
  //     //   icon: ICONS.cart,
  //     //   children: [
  //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //     //   ]
  //     // },

  //     // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //       ]
  //     }
  //   ]
  // }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban
  //     }
  //   ]
  // }
];

export default sidebarConfig;
